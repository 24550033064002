.my-test-border{
    border: #61dafb 1px solid;
}


.new_search {
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 4rem;
}


.left-content{
    width: 100%;
    margin-bottom: 2rem;
}

.new-search-button-div {
    margin-top: 2rem;
    /*height: 4rem;*/
}

.main-box-border {
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 0.5rem 1.25rem rgba(115, 82, 199, 0.175);
}

.max-height-card {
    max-width: 25rem;
}


.wish-card-content {
    padding: .5rem 2.5rem;
}

.header-data-style{
    font-size: .6rem;
    color: #6a6b70;

}

.niche-card-header {
    line-height: 1.2;
    font-weight: 400;
    color: #37373C;
    font-family: NoirPro,Arial;
    margin-bottom: 16px;
}

.niche-card-description {
    margin: 0;
    font-size: .875rem;
    font-family: NoirPro,Arial;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 24px;
    color: #475259;
    text-align: justify;
}
